import mergeProps from "@/router/utils/mergeProps";
import redirectByName from "@/router/utils/redirectByName";


// let ToolsMain = () => import(/* webpackChunkName: "tools" */ '@/tools/ToolsMain')
import ToolsMain from '@/tools/ToolsMain'
let WheelOfLifeToolsView = () => import(/* webpackChunkName: "tools" */ '@/tools/WheelOfLife/WheelOfLifeToolsView')
let ScheduleMaker = () => import(/* webpackChunkName: "scheduleMaker" */ '@/tools/ScheduleMaker/ScheduleMaker')
// let GenogramToolMain = () => import(/* webpackChunkName: "tools" */ '@/tools/GenogramTool/GenogramToolMain')
import GenogramToolMain from '@/tools/GenogramTool/GenogramToolMain'
let TimelineToolMain = () => import(/* webpackChunkName: "tools" */ '@/tools/TimelineTool/TimelineToolMain')
let IFSPartsToolMain = () => import(/* webpackChunkName: "tools" */ '@/tools/IFSMappingParts/IFSPartsToolMain.vue')

let GenogramClassEnroll = () => import(/* webpackChunkName: "genogram-for-class-enroll" */ '@/tools/GenogramTool/Enroll/GenogramClassEnroll.vue')
let GenogramClassLanding = () => import(/* webpackChunkName: "genogram-for-class" */ '@/tools/GenogramTool/Enroll/GenogramClassLanding.vue')

export default [
	{
		path: '/tools',
		name: 'tools',
		component: ToolsMain,
		props: true,
		meta:{
			isWhitelisted: true
		},
		children: [
			{
				path: 'wheel-of-life',
				name: 'WheelOfLife',
				props: true,
				component: WheelOfLifeToolsView,
				meta:{
					isWhitelisted: true
				},
			},
			{
				path: 'genogram',
				name: 'GenogramTool',
				props: route => ({
					...mergeProps(route),
					// settingsParam: {"showStudentEnrollment": true},
				}),

				component: GenogramToolMain,
				meta:{
					isWhitelisted: true
				},
			},
			{
				path: 'genogram-online',
				name: 'GenogramToolNoTrial',
				props: route => ({
					...mergeProps(route),
					settingsParam: {"no-trial-monthly": true},
					otherParams: {
						yearMonthNoTrial: 		 "opt=eyJ5IjogODk3MTY0LCAibSI6IDg5NTk0NCwgInBtIjogOSwgInB5IjogNDl9",
						yearMonthNoTrialStudent: "opt=eyJ5IjogODk3MTY0LCAibSI6IDg5NTk0NCwgInBtIjogOSwgInB5IjogNDksICJzZSI6IHRydWV9",
					}
				}),

				component: GenogramToolMain,
				meta:{
					isWhitelisted: true
				},
			},
			{
				path: 'genogram/:teacherId',
				name: 'GenogramStudent',
				props: route => ({
					...mergeProps(route),
					// settingsParam: {"no-trial-monthly": true},
				}),

				component: GenogramToolMain,
				meta:{
					isWhitelisted: true
				},
			},

			{
				path: 'genogram-for-education',
				redirect: redirectByName('GenogramForClass'),

				children: [
					{
						path: "",
						name: 'GenogramForClass',
						// redirect: redirectByName('GenogramForClassEnroll'),
						props: route => ({
							...mergeProps(route),
							settingsParam: {"planIdYearly": "897643"},
						}),

						component: GenogramClassLanding,
						meta:{
							isWhitelisted: true
						},
					},
					{
						path: "enroll",
						name: "GenogramForClassEnroll",
						props: route => ({
							...mergeProps(route),
							settingsParam: {"planIdYearly": "897643"},
						}),
						component: GenogramClassEnroll,
						meta:{
							isWhitelisted: true
						},

					}
				]

			},

			{
				path: 'genogram-feedback',
				name: 'GenogramToolEmail',
				props: route => ({
					...mergeProps(route),
					settingsParam: {},//{"tutorial-off": true},
					utm_params: {utm_source: "email", utm_campaign: "genogram-cold-lmft"},
				}),

				component: GenogramToolMain,
				meta:{
					isWhitelisted: true
				},
			},
			{
				path: 'genogram-online-feedback',
				name: 'GenogramToolLinkedin',
				props: route => ({
					...mergeProps(route),
					settingsParam: {},
					utm_params: {utm_source: "linkedin", utm_campaign: "genogram-cold-lmft"},
				}),

				component: GenogramToolMain,
				meta:{
					isWhitelisted: true
				},
			},
			{
				path: 'ifs-parts-mapping',
				name: 'IfsPartsMappingTool',
				props: true,
				component: IFSPartsToolMain,
				meta:{
					isWhitelisted: true
				},
			},
			{
				path: 'timeline',
				name: 'TimelineTool',
				props: true,
				component: TimelineToolMain,
				meta:{
					isWhitelisted: true
				},
			},
			{
				path: 'free-schedule-maker',
				name: 'ScheduleMaker',
				component: ScheduleMaker,
				props: true,
				meta:{
					isWhitelisted: true
				},
			},
		]
	},

]
